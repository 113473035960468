import React, { useEffect, useState } from 'react'
import MuiTables from '../../../../Components/MuiTables/MuiTables'
import { useDispatch, useSelector } from 'react-redux'
import { AllStudentGet, StudentDelete } from '../../../../Redux/action/Admin';
import Loader from '../../../../Util/Loader';
import './Student.css';
import { errorNotify, successNotify } from '../../../../Util/toast';
import DynamicModal from '../../../../Components/Modal/Modal';
import { Spinner } from 'react-bootstrap';
import { AiOutlineDownload } from 'react-icons/ai';
import moment from 'moment';

const Students = () => {
    const dispatch = useDispatch();
    const column = ['Full Name', 'Email Address', 'Phone Number', "Per%", 'Date of Joining', 'Action']
    const [searchField, setSearchField] = useState('')
    const [studentData, setStudentData] = useState([])
    const [show, setShow] = useState(false)
    const [studentId, setStudentId] = useState('')

    const { loading, allStudentGetData } = useSelector((state) => state.allStudent)
    const { loading: deleteLoading, studentDeleteData } = useSelector((state) => state.studentDeleteId)

    useEffect(() => {
        dispatch(AllStudentGet())

        if (studentDeleteData) {
            successNotify("Student Deleted Successfully!")
            setShow(false)
            dispatch({ type: "STUDENT_DELETE_RESET" })
        }
    }, [studentDeleteData])

    useEffect(() => {
        setStudentData(allStudentGetData?.data?.rows)
    }, [allStudentGetData])

    const formSubmitHandler = (e) => {
        e.preventDefault();

        if (searchField.length === 0) {
            errorNotify("Please filled up field")
            return;
        }

        let searchData = searchField.toLowerCase();
        const getSearchStudent = allStudentGetData?.data?.rows?.filter((v) => {
            return v?.first_name?.toLowerCase().includes(searchData) ||
                v?.last_name?.toLowerCase().includes(searchData) ||
                v?.user_email?.toLowerCase().includes(searchData) ||
                (v?.user_phone && v?.user_phone?.includes(searchData))
        })
        setStudentData(getSearchStudent)
        setSearchField('')
    }

    const modal = <DynamicModal show={show} setShow={() => setShow(!show)} heading="Delete Student" styleName='profession_delete'>
        <div>
            <p>Are you sure you want to delete?</p>

            <div className='text-end'>
                <button className='yes_btn' onClick={() => dispatch(StudentDelete(studentId))}> {deleteLoading ? <Spinner animation='border' size='sm' /> : 'Yes'} </button>
                <button className='no_btn' onClick={() => setShow(!show)}>No</button>
            </div>
        </div>
    </DynamicModal>

    const convertToCSV = (data) => {
        const selectedFields = data.map((row) => {
            return {
                first_name: row.first_name,
                last_name: row.last_name,
                user_email: row.user_email,
                user_phone: row.user_phone,
                date_joining: moment(row.createdAt).format('l')
            };
        });

        const header = Object.keys(selectedFields[0]).join(',');
        const csv = selectedFields.map((row) => Object.values(row).join(',')).join('\n');
        return header + '\n' + csv;
    };

    const downloadCSV = () => {
        const csvData = convertToCSV(studentData);

        const blob = new Blob([csvData], { type: 'text/csv' });

        const url = window.URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;
        a.download = 'student.csv';
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();

        // Clean up
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
    };

    return (
        <>
            {modal}
            <div className='Divider'>
                <div className='TabName'>
                    All Students
                </div>
            </div>

            <form onSubmit={formSubmitHandler} className='form_search'>
                <input
                    type="text"
                    placeholder="Search by Name or Email or Phone Number"
                    value={searchField}
                    onChange={(e) => setSearchField(e.target.value)}
                    style={{ minWidth: "370px" }}
                />

                <button className="save_btn" type="submit">
                    Search
                </button>
                <button className="save_btn mx-2" type="button" onClick={() => dispatch(AllStudentGet())}>
                    Reset
                </button>
            </form>

            <div className='back_btn my-3 d-flex justify-content-end'>
                <button disabled={loading} onClick={downloadCSV} className='d-flex align-items-center' style={{ gap: "10px" }}>
                    <AiOutlineDownload /> Download CSV
                </button>
            </div>

            {
                loading ? <div style={{ margin: "30px 0 70px 0" }
                } > <Loader /> </div > :
                    <div className='mt-3'>
                        <MuiTables columns={column} data={studentData} getStudentId={setStudentId} setShow={setShow} />
                    </div>
            }
        </>
    )
}
export default Students