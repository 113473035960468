import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#604845',
        color: '#fff',
        fontSize: '17px',
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: '17px',
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const MuiTables = ({ columns, data, getStudentId, setShow }) => {
    const navigate = useNavigate();

    const deleteHandler = (studentId) => {
        getStudentId(studentId)
        setShow(true)
    }

    const withProjectSubmission = data?.filter(item => item?.Project?.length > 0);
    const withoutProjectSubmission = data?.filter(item => item?.Project?.length === 0);

    const dataWithTotalPercentage = withProjectSubmission?.map(item => {
        const totalPercentage = item?.CourseSubmittion?.reduce((total, submission) => total + parseFloat(submission?.percentage), 0);
        const calPercentage = totalPercentage * 100 / (item?.CourseSubmittion?.length * 100)
        return { ...item, calPercentage };
    });

    dataWithTotalPercentage?.sort((a, b) => b.totalPercentage - a.totalPercentage);
    const sortedData = dataWithTotalPercentage?.map(item => ({ ...item, totalPercentage: item?.calPercentage?.toFixed(2) }));
    sortedData?.push(...withoutProjectSubmission);

    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        {columns.map((c, i) => (<StyledTableCell key={i}
                            align={i === 0 ? "" : "right"} >{c}</StyledTableCell>))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {sortedData?.map((d) => {
                        return (
                            <StyledTableRow key={d.id} className={d?.Project?.length > 0 ? 'make_green' : ''}>
                                <StyledTableCell> {`${d.first_name} ${d.last_name}`} </StyledTableCell>
                                <StyledTableCell align="right"> {d.user_email} </StyledTableCell>
                                <StyledTableCell align="right"> {d.user_phone} </StyledTableCell>
                                <StyledTableCell align="right"> {d?.totalPercentage ? `${d?.totalPercentage}%` : '-'} </StyledTableCell>
                                <StyledTableCell align="right"> {moment(d.createdAt).format('l')} </StyledTableCell>
                                <StyledTableCell align="right">
                                    <button className='student_detail' onClick={() => navigate(`/admin/students/${d.id}`)}>Detail</button>
                                    <button className='student_detail mx-1' onClick={() => deleteHandler(d.id)}>Delete</button>
                                </StyledTableCell>
                            </StyledTableRow>
                        )
                    }
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    )
};
export default MuiTables;